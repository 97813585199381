<template>
  <ElDialog
    id="upgrade-plan-dialog"
    :center="true"
    :visible.sync="uiFlags.isVisible"
    :width="$viewport.lt.md ? '100vw': '500px'"
  >
    <template v-if="uiFlags.isVisible">
      <div slot="title">
        <h2 class="mb-1">
          Upgrade
        </h2>
      </div>
      <div class="row justify-content-center gutters-2">
        <div class="col-12">
          <div>
            You are currently on the <strong class="capitalize">{{ currentPlan.name }}</strong>
            plan which is being phased out. You can stay on your current plan until further
            notice, or switch to our new plan. It will allow activating more than
            {{ activationsIncludedInBasic }} listings, use our self-showing features and more.
          </div>
          <div>
            <a
              target="_blank"
              href="https://www.showdigs.com/pricing"
            >Learn more about our new plan here</a>
          </div>
          <div class="mt-3">
            Your monthly charge after upgrading will be ${{ newPlan.price }}
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="row gutter-1 justify-content-center"
      >
        <div class="col-md-4 col">
          <ElButton
            class="w-100"
            size="medium"
            @click="uiFlags.isVisible = false"
          >
            Cancel
          </ElButton>
        </div>
        <div class="col-md-4 col">
          <ElButton
            class="w-100"
            size="medium"
            type="primary"
            :disabled="uiFlags.isButtonLoading"
            :loading="uiFlags.isButtonLoading"
            @click="upgradePlan"
          >
            Upgrade
          </ElButton>
        </div>
      </div>
    </template>
  </ElDialog>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import Plan from '@/constants/Plan';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';

export default {
  name: 'UpgradePlanDialog',
  props: {
    activationsIncludedInBasic: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const { $store } = context.root;

    const uiFlags = reactive({
      isVisible: false,
      isButtonLoading: false,
    });
    const currentPlan = ref();
    const newPlan = ref();

    return {
      uiFlags,
      currentPlan,
      newPlan,
      upgradePlan,
      openUpgradeDialog,
      Plan,
    };

    async function openUpgradeDialog() {
      currentPlan.value = $store.getters['Auth/plan'];
      newPlan.value = (await $store.dispatch('Plan/get')).find((plan) => plan.is_default);
      uiFlags.isVisible = true;
    }

    async function upgradePlan() {
      if (uiFlags.isButtonLoading) {
        return;
      }

      try {
        uiFlags.isButtonLoading = true;
        await $store.dispatch('Subscription/modify');
        showSuccessNotify(context, 'Plan changed successfully');

        context.emit('upgrade-success');
        uiFlags.isVisible = false;
      } catch (error) {
        showErrorNotify(context, error.message);
      } finally {
        uiFlags.isButtonLoading = false;
      }
    }
  },
};
</script>
