<template>
  <SdPage>
    <div class="page-ready-to-start">
      <UpgradePlanDialog
        ref="upgradePlanDialog"
        :activations-included-in-basic="PlanLimits.ListingActivationsIncludedInBasicPlan"
        @upgrade-success="upgradeSuccess"
      />
      <div
        v-if="!canContinueRef"
        class="row align-content-center justify-content-center mb-5 mt-3"
      >
        <div class="activations-limit col-5">
          <div class="row no-gutters">
            <div
              class="col-auto"
              style="margin-top:-5px"
            >
              <i
                class="sdicon-exclamation-triangle text-orange mr-2"
                style="font-size: 24px"
              />
            </div>
            <div class="col">
              You are already using the {{ PlanLimits.ListingActivationsIncludedInBasicPlan }} listing
              activations included in your Basic subscription. Upgrade now to our Standard
              subscription to start showing additional properties
            </div>
          </div>
          <div class="text-right">
            <ElButton
              type="primary"
              size="small"
              @click="doUpgrade"
            >
              Upgrade
            </ElButton>
          </div>
        </div>
      </div>
      <div
        class="row justify-content-center mt-3"
        :class="$viewport.gt.md ? '' : 'text-center'"
      >
        <h2>Ready to start showing this property?</h2>
      </div>
      <div class="row text-center align-content-center justify-content-center">
        <div class="col-md-6">
          <p class="font-21">
            Before you continue to set up showing, please make sure you have these following items available:
          </p>
        </div>
      </div>
      <div class="row text-center align-content-center justify-content-around">
        <div class="col-md-3">
          <img
            :class="$viewport.gt.md ? 'mb-4' : ''"
            src="@/assets/published-listings.svg"
          >
          <p class="text-gray-darker font-weight-strong">
            Published listings
          </p>
          <p>A listings link we'll be sharing with agents and prospects with all the relevant details about the property</p>
        </div>
        <div class="col-md-3">
          <img
            :class="$viewport.gt.md ? 'mb-4 ml-4' : 'ml-4'"
            src="@/assets/way-to-access-property.svg"
          >
          <p class="text-gray-darker font-weight-strong">
            A way to to access the property
          </p>
          <p>For example a lock box, keypad access, or a front desk key pickup</p>
        </div>
        <div class="col-md-3">
          <img
            :class="$viewport.gt.md ? 'mb-4' : 'ml-3'"
            src="@/assets/tenant-info.svg"
          >
          <p class="text-gray-darker font-weight-strong mb-0">
            Current tenant information
          </p>
          <small class="text-gray-dark">In case the property is occupied</small>
          <p class="mt-2">
            If the property is currently occupied, we'll need the current tenant's contact info
            as well as coordinated times when we're able to show the property
          </p>
        </div>
        <div class="col-md-6">
          <ShowingProfileSetupButtons
            v-if="propertyRef"
            :disabled="!canContinueRef || !propertyRef"
            :property="propertyRef"
            :pre-selected-unit="selectedUnitRef"
          />
        </div>
      </div>
    </div>
  </SdPage>
</template>

<script>
import { ref } from '@vue/composition-api';
import { redirectToPropertiesPage } from '@/router';
import Plan from '@/constants/Plan';
import PlanLimits from '@/constants/PlanLimits';
import UpgradePlanDialog from '@/components/billing/UpgradePlanDialog';
import ShowingProfileSetupButtons from '../../../components/showing-profile/ShowingProfileSetupButtons';

export default {
  name: 'ShowingProfileReadyToStart',
  components: {
    ShowingProfileSetupButtons,
    UpgradePlanDialog,
  },
  setup(props, context) {
    const propertyRef = ref(null);
    const selectedUnitRef = ref({});
    const canContinueRef = ref(true);

    getProperty();

    return {
      propertyRef,
      selectedUnitRef,
      redirectToProperties,
      canContinueRef,
      doUpgrade,
      upgradeSuccess,
      PlanLimits,
    };

    async function getProperty() {
      propertyRef.value = await context.root.$store.dispatch(
        'Property/find',
        context.root.$route.params.id,
      );

      canContinueRef.value = !(propertyRef.value.business.subscription.plan.name === Plan.BASIC
        && propertyRef.value.business.activated_units_count >= PlanLimits.ListingActivationsIncludedInBasicPlan);

      selectedUnitRef.value = propertyRef.value.units.find((unit) => unit.id === Number(context.root.$route.params.unit_id));
    }

    function redirectToProperties() {
      redirectToPropertiesPage(context);
    }

    function doUpgrade() {
      context.refs.upgradePlanDialog.openUpgradeDialog();
    }

    function upgradeSuccess() {
      getProperty();
    }
  },
};
</script>

<style lang="scss">
.page-ready-to-start {
  h1 {
    font-size: 1.75rem;
    font-weight: normal;
    color: gray-color(darker);
  }

  > div > p {
    font-size: 1.25rem;
    color: gray-color(darker);
  }

  .select-unit-for-showing-block {
    margin-left: 2rem;
    .el-input__inner {
      min-width: 9rem;
      border-radius: $app-border-radius;
    }

    .el-button {
      border-radius: 0 5px 5px 0;
    }
  }

  .activations-limit {
      padding: 0.75rem;
      background: theme-color('warning-light');
      border-radius: $app-border-radius;
  }
}
</style>
